import '../App.css';
import React, { useEffect } from 'react'

export default function HotWheels() {
    useEffect(() =>{
        document.title = "Hot Wheels"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner nohyperlink'>
                                        Projects
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="gallery">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title" style={{textAlign: "center"}}>Hot Wheels</h2>
                        <h3 className="section-title" style={{textAlign: "center"}}>Carded Mainline</h3>
                        <div className="grid-container">
                            <BuildCardedHotWheels/>
                        </div>
                        <h3 className="section-title" style={{textAlign: "center"}}>Open Mainline</h3>
                        <div className="grid-container">
                            <BuildHotWheels/>
                        </div>
                    </div>
                    </div>
                </div>
                </section>


            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )

    function BuildHotWheels(){
        let HotWheels = [
            ['2016FordGT.jpg', '2016 Ford GT', 'Released 2024'],
            ['2017 Ford GT.jpg', '2017 Ford GT', 'Released 2024'],
            ['Ford Mustang Dark Horse.jpg', 'Ford Mustang Dark Horse', 'Released 2024'],
            ['ToyotaSupra.jpg', 'Toyota Supra', 'Released 2024'],
            ['Lambo Huracan.jpg', 'Lambo Hurácan LP620-2', 'Released 2024'],
            ['McLarenP1.jpg', 'McLaren P1', 'Released 2024'],
            ['1996PorscheCarrera.jpg', '96 Porsche Carrera', 'Released 2024'],
            ['Koenigsegg Jesko.jpg', 'Koenigsegg Jesko', 'Released 2024'],
            ['Nimac Revera.jpg', 'Nimac Revera', 'Released 2023'],
            ['Volvo GTV6.jpg', 'Volvo GTV6 3.0', 'Released 2024'],
            ['McLaren F1.jpg', 'McLaren F1', 'Released 2024'], 
            ['1984 Ford Mustang SVO Roxo.jpg', '84 Ford Mustang SVO', 'Released 2022'],
            ['1984 Ford Mustang SVO Preto.jpg', '84 Ford Mustang SVO', 'Released 2023'],
            ['Aston Martin DB5.jpg', 'Aston Martin DB5', 'Released 2024'],
            ['Bugatti Bolide.jpg', 'Bugatti Bolide', 'Released 2024'],
            ['Covertte C8.R.jpg', 'Corvette C8.R', 'Released 2024'],
            ['Dodge Viper ACR10.jpg', 'Dodge Viper ACR10', 'Released 2024'],
            ['Maxda MX-5 Miata Amarelo.jpg', '91 Mazda MX-5 Miata', 'Released 2024'],
            ['Mazda RX-7 Azul.jpg', '95 Mazda RX-7', 'Released 2024'],
            ['Mazda RX-7 Preto.jpg', 'Mazda RX-7', 'Released 2024'],
            ['Mazda RX-7 Savanna.jpg', 'Mazda RX-7 Savanna', 'Released 2023'],
            ['Nissan GT-R LBWK.jpg', 'Nissan LB-Silhouette 35GT-RR', 'Released 2024'],
        ]
        return(
            HotWheels.map((hw, index) =>{
                return(
                    <div className="grid-item" >
                        <h3>{HotWheels[index][1]}</h3>
                        <a style={{cursor: "pointer"}} href={"Hot Wheels/" + HotWheels[index][0]} target="_blank">
                            <img src={"Hot Wheels/" + HotWheels[index][0]} alt="Description 3" className="grid-image" />
                        </a>
                        <p>{HotWheels[index][2]}</p>
                    </div>
                )
            })
        )
    }

    function BuildCardedHotWheels(){
        let HotWheels = [
            ['Lambo Huracan.jpg', 'STH Lambo Hurácan LP620-2', 'Released 2024'],
            ['DMC Delorean.jpg', 'TH DMC DeLorean', 'Released 2024'],
            ['Porsche 928S Safari.jpg', 'TH Porsche 928S Safari', 'Released 2024'],
            ['Bumblebee.jpg', 'Bumblebee', 'Released 2024'],
            ['Lambo Sesto Elemento.jpg', 'Lamborghini Sesto Elemento', 'Released 2024'],
            ['Mazda MX-5 Miata.jpg', 'Mazda MX-5 Miata', 'Released 2024'],
            ['Pagani Utopia.jpg', 'Pagani Utopia', 'Released 2024'],  
        ]
        return(
            HotWheels.map((hw, index) =>{
                return(
                    <div className="grid-item" >
                        <h3>{HotWheels[index][1]}</h3>
                        <a style={{cursor: "pointer"}} href={"Hot Wheels/Carded/" + HotWheels[index][0]} target="_blank">
                            <img src={"Hot Wheels/Carded/" + HotWheels[index][0]} alt="Description 3" className="grid-image" />
                        </a>
                        <p>{HotWheels[index][2]}</p>
                    </div>
                )
            })
        )
    }
}
