import { Route, Routes } from "react-router-dom";
import LandingPage from './Pages/LandingPage';
import Projects from './Pages/Projects'
import Contact from './Pages/Contact'
import HotWheels from "./Pages/HotWheels";
import React from 'react'

export default function MainPage(){
    return (
        <div>
            <Routes>
                <Route path='/' element={<LandingPage></LandingPage>}>
                </Route>
                <Route path='projects' element={<Projects/>}>
                </Route>
                <Route path='contact' element={<Contact/>}>
                </Route>
                <Route path='hotwheels' element={<HotWheels/>}>
                </Route>
            </Routes>
        </div>
    )
}