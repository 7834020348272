import '../App.css';
import React, { useEffect } from 'react'
import emailjs from 'emailjs-com';

export default function Contact() {
    useEffect(() =>{
        document.title = "Rafael Azevedo"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner nohyperlink'>
                                        Projects
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner special-link'>
                                        Contact me
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="section-title">Contact me</h2>
                            <form onSubmit={sendEmail}>
                                <div className="form-group mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="form-control custom-input"
                                        placeholder="Enter your name"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control custom-input"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="reason" className="form-label">Reason</label>
                                    <input
                                        type="text"
                                        id="reason"
                                        name="reason"
                                        className="form-control custom-input"
                                        placeholder="Enter your reason"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="message" className="form-label">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        className="form-control custom-input"
                                        rows="5"
                                        placeholder="Enter your message"
                                        style={{resize: "none"}}
                                        required
                                    ></textarea>
                                </div>
                                <button type="submit" className="btn btn-warning">
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )

    function sendEmail(){
        let name = document.getElementById("name")
        let email = document.getElementById("name")
        let reason = document.getElementById("reason")
        let message = document.getElementById("message")

        const template = {
            name: name,
            email: email,
            reason: reason,
            message: message
        }

        emailjs.sendForm('service_48l95o9', 'template_ecq9rip', template, 'TK0QUgnnVdN66HmVv')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }
}
